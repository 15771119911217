import React from "react";

function Team() {
  return (
    <div className="mt-[60px]">
      <h1 className="uppercase font-black text-white text-[40px] md:text-[80px]">
        Team
      </h1>
      <p className="my-[20px] text-[20px]">Your homies through the metaverse</p>

      <div className="grid gap-7 sm:grid-cols-2 md:grid-cols-3">
        <div className="teamMemberContainer">
          <div className="relative w-[200px] h-[200px] mx-auto">
            <div className="absolute top-0 right-0 z-10">
              <a
                href="https://www.instagram.com/thevisualmartian/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/instagram-icon-white-on-black.png"
                  alt="Instagram logo"
                  className="h-[33px] mt-3"
                />
              </a>
            </div>
            <img
              src="/team/Martian.png"
              alt="Martian Yutes"
              className=" w-[200px] h-[200px]"
            />
          </div>
          <p className="text-center font-bold">Martian</p>
          <p className="text-center">Founder | Artist | Developer</p>
        </div>

        <div className="teamMemberContainer">
          <div className="relative w-[200px] h-[200px] mx-auto">
            <img
              src="/team/newBorn20.png"
              alt="NewBorn20 Yute"
              className=" w-[200px] h-[200px]"
            />
          </div>
          <p className="text-center font-bold">NewBorn20</p>
          <p className="text-center">Developer</p>
        </div>

        <div className="teamMemberContainer">
          <div className="relative w-[200px] h-[200px] mx-auto">
            <img
              src="/team/EMase.png"
              alt="EMase Yute"
              className=" w-[200px] h-[200px]"
            />
          </div>
          <p className="text-center font-bold">EMase</p>
          <p className="text-center">Accountant</p>
        </div>

        <div className="teamMemberContainer">
          <div className="relative w-[200px] h-[200px] mx-auto">
            <div className="absolute top-0 right-0 z-10">
              <a
                href="https://twitter.com/aetherhawk01/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/Twitter logo png.png"
                  alt="Instagram logo"
                  className="h-[30px] mt-3 mr-[7px]"
                />
              </a>
            </div>
            <img
              src="/team/MindFlayer.png"
              alt="EMase Yute"
              className=" w-[200px] h-[200px]"
            />
          </div>
          <p className="text-center font-bold">Aether</p>
          <p className="text-center">Developer</p>
        </div>
      </div>
    </div>
  );
}

export default Team;
