import React, { useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { mintSuccessModalState } from "../atoms/mintSuccessModalAtom";
import { txHashState } from "../atoms/txHashAtom";

function MintedModal() {
  const [showModal, setShowModal] = useRecoilState(mintSuccessModalState);
  const txHash = useRecoilValue(txHashState);
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  return (
    <div>
      {showModal && (
        <div
          className="fixed flex top-0 left-0 right-0 bottom-0 items-center justify-center h-screen bg-gray-600/60 z-20"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="centeredOnScreen w-[300px] bg-white border-2 border-black p-10 text-center shadow-md">
            <h1 className="font-black">NFT Minted!</h1>
            <p>Your NFT will show up in your wallet in the next few minutes.</p>

            <p className="mt-4">
              view on{" "}
              <span>
                <a
                  href={`https://etherscan.io/tx/${txHash}`}
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  Etherscan
                </a>
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default MintedModal;
