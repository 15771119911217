import React from "react";
import ConnectButton from "../wallet/ConnectButton";

function ConnectToMint() {
  return (
    <div>
      <p className="text-center text-[15px] md:text-[25px] mb-2 md:mb-3 text-red-400">
        You must be connected to Mint!
      </p>
      <div className="flex justify-center">
        <div className="border-[3px] rounded-md border-black md:border-0 md:rounded-none">
          <ConnectButton />
        </div>
      </div>
    </div>
  );
}

export default ConnectToMint;
