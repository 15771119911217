import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";

const faq = [
  {
    question: "What is Yutes On The Block?",
    answer:
      "Yutes On The Block is a community first NFT collectible, Owning one is your membership card.",
    id: 1,
  },
  { question: "What is the collection size?", answer: "10,000 Yutes", id: 2 },
  {
    question: "Mint price?",
    answer: "Whitelist mint is .06 ETH and .08 ETH for Public mint",
    id: 3,
  },
  {
    question: "Where to mint?",
    answer: "On the mint page is where you will be able to mint your Yute.",
    id: 4,
  },
  {
    question: "How to mint?",
    answer:
      " Connect your Ethereum wallet, select how much Yutes you would like to mint and then click on the mint button.",
    id: 5,
  },
  {
    question: "How to get whitelisted?",
    answer: "Follow the socials and be an active member in the community!",
    id: 6,
  },
  {
    question: "What is a NFT?",
    answer:
      "NFT stands for non-fungible token which is a non-interchangeable unit of data stored on a blockchain, a form of digital ledger, that can be sold and traded.",
    id: 7,
  },
  {
    question: "What can I do with my Yute?",
    answer: "Ownership and commercial usage rights given to YuteHolders",
    id: 8,
  },
];

function FAQ() {
  const [selected, setSelected] = useState([]);

  const toggleAnswer = (id) => {
    setSelected((prev) => [...prev, id]);
  };

  const hideAnswer = (id) => {
    setSelected((prev) => prev.filter((item) => item !== id));
  };

  return (
    <div className="my-[60px]">
      <h1 className="uppercase font-black text-white text-[40px] md:text-[80px]">
        FAQ
      </h1>
      {faq?.map((item) => {
        const { question, answer, id } = item;
        return (
          <div key={id} className="mt-[20px]">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                if (selected?.includes(id)) {
                  return hideAnswer(id);
                } else {
                  toggleAnswer(id);
                }
              }}
            >
              <p className="text-[15px] md:text-[20px] font-bold">{question}</p>

              {selected.includes(id) ? (
                <ChevronUpIcon className="h-7 ml-4" />
              ) : (
                <ChevronDownIcon className="h-7 ml-4" />
              )}
            </div>

            {selected.includes(id) && <p>{answer}</p>}
          </div>
        );
      })}
    </div>
  );
}

export default FAQ;
