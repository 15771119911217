import React from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./nav/MobileMenu";
import NavBar from "./nav/NavBar";

function Header() {
  return (
    <div className="sticky top-0 flex justify-between items-center md:pl-5 md:pr-10 z-20 bg-white/10">
      <div className="flex items-center">
        <Link to="/">
          <img
            src="/YOTB LOGO.png"
            alt="Yutes On The Block Logo"
            className="min-h-[80px] min-w-[80px] max-h-[80px] max-w-[80px] md:min-h-[150px] md:min-w-[150px] md:max-h-[150px] md:max-w-[150px]"
          />
        </Link>

        {/* Socials */}
        <div className="flex items-center md:ml-5">
          <a
            href="https://discord.gg/VWPQSFMyf5"
            target="_blank"
            rel="noreferrer"
            className="mx-3"
          >
            <img
              src="/discord-black-icon-1.png"
              alt="Discord"
              className="min-h-[30px] min-w-[30px] max-h-[30px] max-w-[30px] md:min-h-[35px] md:min-w-[35px] md:max-h-[35px] md:max-w-[35px]"
            />
          </a>

          <a
            href="https://twitter.com/YutesOnTheBlock"
            target="_blank"
            rel="noreferrer"
            className=" mx-3"
          >
            <img
              src="/Twitter logo png.png"
              alt="Twitter"
              className="min-h-[25px] min-w-[25px] max-h-[25px] max-w-[25px] md:min-h-[30px] md:min-w-[30px] md:max-h-[30px] md:max-w-[30px]"
            />
          </a>
        </div>
      </div>
      <NavBar />
      <MobileMenu />
    </div>
  );
}

export default Header;
