import React, { useRef } from "react";
import { useRecoilState } from "recoil";
import { useConnect } from "wagmi";
import { connectModalState } from "../components/atoms/connectModalAtom";
import { XIcon } from "@heroicons/react/solid";

const walletsLogo = [
  {
    name: "MetaMask",
    image: "/wallets/MetaMask_Fox.svg.png",
    height: "h-[40px]",
    width: "w-[40px]",
  },
  {
    name: "WalletConnect",
    image: "/wallets/WalletConnect-Logo.png",
    height: "h-[40px]",
    width: "w-[71px]",
  },
  {
    name: "Coinbase Wallet",
    image: "/wallets/Coinbase-dapp-wallet-logo.png",
    height: "h-[40px]",
    width: "w-[40px]",
  },
  {
    name: "Injected",
    image: "/wallets/eth-diamond-purple.png",
    height: "h-[40px]",
    width: "w-[24px]",
  },
];

function ConnectModal() {
  const [showModal, setShowModal] = useRecoilState(connectModalState);
  const modalRef = useRef();

  const { connectors, connect } = useConnect();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  return (
    <div>
      {showModal && (
        <div
          className="fixed flex top-0 left-0 right-0 bottom-0 items-center justify-center h-screen bg-gray-600/60 z-20"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="centeredOnScreen w-[300px] bg-black rounded-md text-white shadow-md">
            <div className="flex justify-between items-center p-4 border-b-2 border-black">
              <h1 className="flex-1 text-center font-black text-xl uppercase">
                Wallets
              </h1>
              <button
                className="border-[3px] border-black rounded-sm hover:bg-red-500"
                onClick={() => setShowModal(false)}
              >
                <XIcon className="h-7" />
              </button>
            </div>
            <div className="grid grid-cols-1 gap-2 p-4">
              {connectors?.map((connector) => {
                const { id, name } = connector;
                return (
                  <button
                    key={id}
                    onClick={() => {
                      connect({ connector });
                      setShowModal(false);
                    }}
                    className={`flex justify-between items-center border-[3px] hover:border-blue-500 bg-white text-black rounded-md p-3 hover:scale-95`}
                  >
                    <div className="w-20">
                      {walletsLogo?.map((logo) => {
                        const { name: logoName, image } = logo;
                        if (logoName === name) {
                          return (
                            <div key={logoName}>
                              <img
                                src={image}
                                alt={logoName}
                                className={`mx-auto  h-[40px]`}
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                    <p className="flex-1 text-left font-bold">{name}</p>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConnectModal;
