import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import RoadmapOne from "../components/roadmap/RoadmapOne";
import RoadmapTwo from "../components/roadmap/RoadmapTwo";
import ConnectModal from "../connect/ConnectModal";
import { Helmet } from "react-helmet";

function RoadMap() {
  return (
    <div className="bg-[#BF3636]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yutes On The Block | Roadmap</title>
        <link rel="icon" href="/YOTB White ICO.ico" />
      </Helmet>
      <Header />
      <div className="max-w-[80%] mx-auto">
        <div className="md:pl-10">
          <RoadmapOne />
          <RoadmapTwo />
        </div>
      </div>
      <ConnectModal />
      <Footer />
    </div>
  );
}

export default RoadMap;
