import { Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { configureChains, WagmiConfig, chain, createClient } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import Mint from "./pages/Mint";
import RoadMap from "./pages/RoadMap";
import TheBlock from "./pages/TheBlock";

const alchemyId = "j69eHxHx6CVgDZ3zkIHJq3QkT796akC1";
// const testId = "gBji75hSmvFiGvYWSL6NxrVL8-ir4sIl";

const { chains, provider, webSocketProvider } = configureChains(
  [chain.mainnet],
  [
    alchemyProvider({ apiKey: "j69eHxHx6CVgDZ3zkIHJq3QkT796akC1" }),
    publicProvider(),
  ]
);

// const { chains, provider, webSocketProvider } = configureChains(
//   [chain.goerli],
//   [alchemyProvider({ testId })]
// );

// const client = createClient({
//   autoConnect: true,
//   provider,
//   webSocketProvider,
// });

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
        rpc: {
          1: `https://eth-mainnet.g.alchemy.com/v2/j69eHxHx6CVgDZ3zkIHJq3QkT796akC1`,
        },
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "YutesOnTheBlock",
        jsonRpcUrl: `https://eth-mainnet.g.alchemy.com/v2/j69eHxHx6CVgDZ3zkIHJq3QkT796akC1`,
      },
    }),
    new InjectedConnector({ chains, options: { name: "Injected" } }),
  ],
  provider,
  webSocketProvider,
});

// console.log(alchemyId);

function App() {
  return (
    <RecoilRoot>
      <WagmiConfig client={client}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/theblock" element={<TheBlock />} />
          <Route path="/roadmap" element={<RoadMap />} />
        </Routes>
        <ToastContainer position="top-center" transition={Slide} />
      </WagmiConfig>
    </RecoilRoot>
  );
}

export default App;
