import React from "react";

function BackgroundImage() {
  return (
    <div className="absolute bottom-0 left-0 right-0">
      <img
        src="/YOTBhomepageBackground.png"
        alt="Bg image"
        className="mx-auto"
      />
    </div>
  );
}

export default BackgroundImage;
