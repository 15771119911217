import React, { useEffect, useState } from "react";
import { ethers, BigNumber } from "ethers";
import {
  useAccount,
  useSigner,
  useContract,
  useContractRead,
  useBalance,
  useWaitForTransaction,
} from "wagmi";
import { toast } from "react-toastify";
import yutesOnTheBlockNFT from "../../yutesOnTheBlockNFT.json";
import { useRecoilState } from "recoil";
import { txHashState } from "../atoms/txHashAtom";
import { mintSuccessModalState } from "../atoms/mintSuccessModalAtom";
import useFetchMerkleProof from "../../hooks/useFetchMerkleProof";

const NFTAddress = "0x15b92D18bDdf64a2F4c813A9BA6D6AfF0eC520ED";

// Test Address
// const NFTAddress = "0x0C324Bd27d93B624d611a13a27dCa057C167fa7C";

function MainMint() {
  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();

  const [mintAmount, setMintAmount] = useState(1);
  const [publicETHPrice, setPublicETHPrice] = useState(1);
  const [whitelistETHPrice, setWhitelistETHPrice] = useState(1);
  const [yutes, setYutes] = useState([]);

  const [txHash, setTxHash] = useRecoilState(txHashState);
  const [showModal, setShowModal] = useRecoilState(mintSuccessModalState);

  const { data: balance } = useBalance({
    addressOrName: address,
    watch: false,
  });

  const { data: totalSupply } = useContractRead({
    addressOrName: NFTAddress,
    contractInterface: yutesOnTheBlockNFT,
    functionName: "totalSupply",
    watch: false,
  });

  const { data: collectionSize } = useContractRead({
    addressOrName: NFTAddress,
    contractInterface: yutesOnTheBlockNFT,
    functionName: "collectionSize",
  });

  const { data: maxMintPerWallet } = useContractRead({
    addressOrName: NFTAddress,
    contractInterface: yutesOnTheBlockNFT,
    functionName: "maxMintPerWallet",
    watch: false,
  });

  const { data: nftsMinted } = useContractRead({
    addressOrName: NFTAddress,
    contractInterface: yutesOnTheBlockNFT,
    functionName: "walletMints",
    args: [address],
    watch: false,
  });

  const { data: mintPublic } = useContractRead({
    addressOrName: NFTAddress,
    contractInterface: yutesOnTheBlockNFT,
    functionName: "publicSale",
  });

  const { data: mintWhitelist } = useContractRead({
    addressOrName: NFTAddress,
    contractInterface: yutesOnTheBlockNFT,
    functionName: "whitelistSale",
  });

  const { data: whitelistPrice } = useContractRead({
    addressOrName: NFTAddress,
    contractInterface: yutesOnTheBlockNFT,
    functionName: "whitelistMintPrice",
  });

  const { data: publicPrice } = useContractRead({
    addressOrName: NFTAddress,
    contractInterface: yutesOnTheBlockNFT,
    functionName: "publicMintPrice",
  });

  const contract = useContract({
    addressOrName: NFTAddress,
    contractInterface: yutesOnTheBlockNFT,
    signerOrProvider: signer,
  });

  const { isSuccess: isMinted, isLoading: processing } = useWaitForTransaction({
    hash: txHash,
  });

  const [hexProof, whitelisted] = useFetchMerkleProof(address);

  const publicWEIValue = (publicETHPrice * 1e18).toString();
  const whitelistWEIValue = (whitelistETHPrice * 1e18).toString();

  const publicMint = async () => {
    if (isConnected) {
      try {
        const response = await contract.publicMint(BigNumber.from(mintAmount), {
          value: BigNumber.from(publicWEIValue),
        });
        console.log(response);
        setTxHash(response.hash);
      } catch (error) {
        console.error(error);
        toast.error(error.reason);
      }
    }
  };

  const whitelistMint = async () => {
    if (isConnected) {
      try {
        const response = await contract.whitelistMint(
          hexProof,
          BigNumber.from(mintAmount),
          {
            value: BigNumber.from(whitelistWEIValue),
          }
        );
        console.log("response: ", response);
        setTxHash(response.hash);
      } catch (error) {
        console.error(error);
        toast.error(error.reason);
      }
    }
  };

  const handleDecrement = () => {
    if (mintAmount <= 1) return;
    setMintAmount(mintAmount - 1);
  };

  const handleIncrement = () => {
    if (mintAmount >= parseInt(maxMintPerWallet) - parseInt(nftsMinted)) return;
    setMintAmount(mintAmount + 1);
  };

  useEffect(() => {
    const yutes = [...Array(mintAmount)].map((_, i) => ({
      mark: "?",
      id: i,
    }));
    setYutes(yutes);
    if (parseInt(publicPrice) > 0) {
      setPublicETHPrice(ethers?.utils?.formatUnits(publicPrice) * mintAmount);
    }

    if (parseInt(whitelistPrice) > 0) {
      setWhitelistETHPrice(
        ethers?.utils?.formatUnits(whitelistPrice) * mintAmount
      );
    }
  }, [mintAmount, publicPrice, whitelistPrice]);

  useEffect(() => {
    setShowModal(isMinted);
  }, [isMinted]);

  return (
    <>
      <div className="mb-3 flex justify-center">
        {/* {mintWhitelist && (
          <p className="font-black uppercase text-[12px] md:text-[14px]">
            whitelist mint:
            <span className="text-red-400">
              {whitelisted
                ? " You are whitelisted"
                : " You are not whitelisted"}
            </span>
          </p>
        )} */}
        {/* {mintPublic && ( */}
        <p className="font-black uppercase text-[12px] md:text-[14px]">
          public mint
        </p>
        {/* )} */}
      </div>
      <div className="flex justify-center items-center">
        {yutes?.map((yute) => {
          return (
            <div
              key={yute.id}
              className={yutes.length > 1 ? `mx-[25px] shadow-md` : `shadow-md`}
            >
              <div className="relative bg-gray-300 h-[200px] w-[200px]">
                <p className="centeredOnScreen font-black text-red-500 text-[60px] z-10">
                  {yute.mark}
                </p>
                <div className="centeredOnScreen bg-white/90 h-[200px] w-[200px] mix-blend-hard-light"></div>
                <img
                  src="/YuteMysteryGIF.gif"
                  alt="gif"
                  className="h-[200px] w-[200px]"
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center items-center my-5">
        {parseInt(totalSupply) > 1000 && (
          <p className="font-black uppercase">
            {parseInt(totalSupply)} / {parseInt(collectionSize)} yutes
          </p>
        )}
      </div>
      {/* Mint */}
      {parseInt(nftsMinted) === parseInt(maxMintPerWallet) ? (
        <p className="font-black text-center md:text-3xl">
          You have minted the max amount per wallet!
        </p>
      ) : (
        <>
          <div className="flex justify-center items-center">
            <button
              className="text-[40px] font-black text-white bg-black h-[60px] w-[60px]"
              onClick={handleDecrement}
            >
              -
            </button>
            <div className="w-[100px] h-[60px] flex justify-center items-center border-y-2 border-black">
              <p className="text-[40px] font-black">{mintAmount}</p>
            </div>
            <button
              className="text-[40px] font-black text-white bg-black h-[60px] w-[60px]"
              onClick={handleIncrement}
            >
              +
            </button>
          </div>
          <div className="flex justify-center mt-5">
            {/* {!mintWhitelist && !mintPublic ? (
              <p className="bg-black w-[160px] h-[50px] font-black text-[30px] text-white text-center">
                Oct 20
              </p>
            ) : (
              <>
                {mintWhitelist && (
                  <div>
                    <button
                      className={`bg-black w-[160px] h-[50px] font-black text-[30px] text-white ${
                        whitelisted ? "bg-black" : "bg-red-500 cursor-default"
                      } `}
                      onClick={() => {
                        whitelistMint();
                      }}
                      disabled={processing}
                    >
                      {!processing ? (
                        <p>Mint</p>
                      ) : (
                        <p className="animate-pulse">Minting...</p>
                      )}
                    </button>
                    <p className="text-center uppercase font-semibold mt-2 text-[12px] text-green-500">
                      price: {whitelistETHPrice} ETH + gas
                    </p>
                  </div>
                )} */}

            {/* {mintPublic && ( */}
            <div>
              <button
                className="bg-black min-w-[160px] h-[50px] font-black text-[30px] text-white"
                onClick={() => publicMint()}
                disabled={processing}
              >
                {!processing ? (
                  <p>Mint</p>
                ) : (
                  <p className="animate-pulse">Minting...</p>
                )}
              </button>
              <p className="text-center uppercase font-semibold mt-2 text-[12px]">
                price: {publicETHPrice} ETH + gas
              </p>
            </div>
            {/* )} */}
            {/* </>
            )} */}
          </div>
        </>
      )}{" "}
      {balance && (
        <p className="font-semibold text-center text-[12px]">
          Wallet balance: {parseFloat(balance.formatted).toFixed(4)} ETH
        </p>
      )}
    </>
  );
}

export default MainMint;
