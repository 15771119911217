import React, { useEffect, useState } from "react";

const yutes = [
  "/yutes/Yute 1 site.png",
  "/yutes/Yute 2 site.png",
  "/yutes/Yute 3 site.png",
  "/yutes/Yute 4 site.png",
  "/yutes/Yute 5 site.png",
  "/yutes/Yute 6 site.png",
  "/yutes/Yute 7 site.png",
  "/yutes/Yute 8 site.png",
];

function Yutes() {
  const [yuteImages, setYuteImages] = useState(yutes);
  const [displayImage, setDislayImage] = useState(0);

  useEffect(() => {
    const length = yuteImages.length;
    setTimeout(() => {
      if (displayImage >= length - 1) {
        setDislayImage(0);
      } else {
        setDislayImage(displayImage + 1);
      }
    }, 500);
  }, [displayImage, yuteImages.length]);

  return (
    <div className="hidden xl:flex xl:fixed right-0 lg:mr-[10%]">
      <img
        src={yuteImages[displayImage]}
        alt={`Yute ${displayImage + 1}`}
        className="w-[360px] h-[500px] mx-auto"
      />
    </div>
  );
}

export default Yutes;
