import React from "react";

function TheBlockSection() {
  return (
    <div className="mt-[60px]">
      <h1 className="uppercase font-black text-white text-[40px] md:text-[80px]">
        The Block
      </h1>

      <p className="mt-[30px] text-[20px]">
        On the block is where all the Yutes come to chill, empower, and grow.
        For we know we are stronger together!
      </p>
      <p className="mt-[30px] text-[20px]">
        The Yutes On The Block is a collection of 10,000 randomly generated
        unique digital collectibles living on the Ethereum blockchain. YOTB
        holders will receive access to exclusive drops, experiences, community
        investing, and more.
      </p>
      <p className="mt-[30px] text-[20px]">We Are One!</p>
    </div>
  );
}

export default TheBlockSection;
