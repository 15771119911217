import { useEffect, useState } from "react";
import whitelistAddresses from "../whitelist/whitelist.json";
const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");

window.Buffer = window.Buffer || require("buffer").Buffer;

function useFetchMerkleProof(address) {
  const [proof, setProof] = useState([]);

  const leafNodes = whitelistAddresses.map((addr) => keccak256(addr));
  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

  const rootHash = merkleTree.getRoot();
  // console.log("whitelist Merkle Tree\n", merkleTree.toString());
  // console.log("Root Hash: ", `0x${rootHash.toString("hex")}`);

  const claimingAddress = keccak256(address);
  const hexProof = merkleTree.getHexProof(claimingAddress);
  const whitelisted = merkleTree.verify(hexProof, keccak256(address), rootHash);

  useEffect(() => {
    setProof(hexProof);
  }, [address]);

  return [proof, whitelisted];
}

export default useFetchMerkleProof;
