import { Menu } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/solid";
import {
  OfficeBuildingIcon,
  MapIcon,
  NewspaperIcon,
  ViewGridIcon,
  PuzzleIcon,
  LogoutIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import Wallet from "../wallet/Wallet";

function MobileMenu() {
  const { isConnected } = useAccount();
  return (
    <div className="lg:hidden">
      <Menu as="div" className="mr-5">
        <Menu.Button
          className="lg:hidden flex justify-center items-center content-center"
          name="Menu button"
          aria-label="Menu button"
        >
          <MenuIcon className="h-7 lg:hidden hover:text-blue-500" />
        </Menu.Button>

        <Menu.Items className="origin-top-right absolute right-0 mr-5 mt-4 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="rounded-md">
            <Menu.Item>
              {({ active }) => (
                <Link to="/mint">
                  <p
                    className={`flex items-center relative z-50  px-4 py-2 ${
                      active && "bg-blue-500 text-white rounded-t-md"
                    }`}
                  >
                    <PuzzleIcon className="h-6 mr-3" />
                    Mint
                  </p>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link to="/theblock">
                  <p
                    className={`flex items-center relative z-50  px-4 py-2 ${
                      active && "bg-blue-500 text-white"
                    }`}
                  >
                    <ViewGridIcon className="h-6 mr-3" />
                    The Block
                  </p>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link to="/roadmap">
                  <p
                    className={`flex items-center relative z-50  px-4 py-2 ${
                      active && "bg-blue-500 text-white"
                    }`}
                  >
                    <MapIcon className="h-6 mr-3" />
                    Roadmap
                  </p>
                </Link>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <p
                  className={`flex items-center relative z-50  px-4 py-2 text-gray-300 ${
                    active && "bg-blue-500 text-white rounded-b-md"
                  }`}
                >
                  <NewspaperIcon className="h-6 mr-3" />
                  Treasure hunt
                </p>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <p
                  className={`flex items-center relative z-50  px-4 py-2 text-gray-300 ${
                    active && "bg-blue-500 text-white rounded-b-md"
                  }`}
                >
                  <OfficeBuildingIcon className="h-6 mr-3" />
                  Corner store
                </p>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <div className="flex items-center relative z-50 ">
                  {isConnected && <LogoutIcon className="h-6 ml-[20px]" />}
                  <Wallet />
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
}

export default MobileMenu;
