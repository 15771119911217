import React from "react";
import { Link } from "react-router-dom";
import Wallet from "../wallet/Wallet";
function NavBar() {
  return (
    <div className="hidden lg:flex items-center">
      <Link to="/mint">
        <p className="navButton">Mint</p>
      </Link>
      <Link to="/theblock">
        <p className="navButton">The Block</p>
      </Link>
      <Link to="/roadmap">
        <p className="navButton">Roadmap</p>
      </Link>

      <button className="font-black mx-3 text-xl uppercase cursor-not-allowed">
        Treasure hunt
      </button>
      <button className="font-black mx-3 text-xl uppercase cursor-not-allowed">
        Corner store
      </button>

      <Wallet />
    </div>
  );
}

export default NavBar;
