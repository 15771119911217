import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MoveIn from "../components/theBlockPage/MoveIn";
import Team from "../components/theBlockPage/Team";
import TheBlockSection from "../components/theBlockPage/TheBlockSection";
import ConnectModal from "../connect/ConnectModal";
import FAQ from "../components/theBlockPage/FAQ";
import Yutes from "../components/theBlockPage/Yutes";
import { Helmet } from "react-helmet";

function TheBlock() {
  return (
    <div className="bg-[#F2D43D]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yutes On The Block | The block</title>
        <link rel="icon" href="/YOTB White ICO.ico" />
      </Helmet>
      <Header />
      <div className="flex justify-between max-w-[80%] mx-auto">
        <div className="md:pl-10 max-w-[690px]">
          <TheBlockSection />

          <Team />

          <MoveIn />

          <FAQ />
        </div>
        <Yutes />
      </div>
      <div className="pb-10">
        <p className="text-center text-[12px] md:text-[16px] font-black text-[#003A91]">
          CONTRACT ADDRESS:{" "}
          <span className="underline">
            <a
              href="https://etherscan.io/address/0x15b92d18bddf64a2f4c813a9ba6d6aff0ec520ed#code"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              0x15b92D18bDdf64a2F4c813A9BA6D6AfF0eC520ED
            </a>
          </span>{" "}
        </p>
      </div>
      <ConnectModal />
      <Footer />
    </div>
  );
}

export default TheBlock;
