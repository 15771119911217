import { useAccount } from "wagmi";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ConnectModal from "../connect/ConnectModal";
import MainMint from "../components/mintPage/MainMint";
import ConnectToMint from "../components/mintPage/ConnectToMint";
import { Helmet } from "react-helmet";
import MintedModal from "../components/mintPage/MintedModal";

function Mint() {
  const [connected, setConnected] = useState(false);
  const { isConnected } = useAccount();

  useEffect(() => {
    setConnected(isConnected);
  }, [isConnected]);
  return (
    <div className="relative h-screen bg-[#08793B]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yutes On The Block | Mint</title>
        <link rel="icon" href="/YOTB White ICO.ico" />
      </Helmet>
      <Header />

      <div className="fixed flex top-0 left-0 right-0 bottom-0 items-center justify-center h-screen">
        <div className="centeredOnScreen mt-16">
          <h1 className="font-black text-white text-[22px] md:text-[40px] text-center">
            Yutes On The Block
          </h1>

          {connected ? <MainMint /> : <ConnectToMint />}
        </div>
      </div>
      <ConnectModal />
      <MintedModal />
      <Footer />
    </div>
  );
}

export default Mint;
