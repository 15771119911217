import React from "react";
import { useAccount } from "wagmi";
import DisconnectButton from "./DisconnectButton";
import ConnectButton from "./ConnectButton";

function Wallet() {
  const { isConnected } = useAccount();
  return <>{isConnected ? <DisconnectButton /> : <ConnectButton />}</>;
}

export default Wallet;
