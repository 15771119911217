import React from "react";

function Footer() {
  return (
    <div className="fixed bottom-0 right-0 rotate-[-90deg] mb-16">
      <div className="flex items-center mt-20">
        <img
          src="/Copyright.png"
          alt="copyright logo"
          className=" h-[12px] w-[12px] mr-1"
        />

        <p className="text-[12px]">2022 Jab Jab Crew</p>
      </div>
    </div>
  );
}

export default Footer;
