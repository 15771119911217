import React from "react";
import BackgroundImage from "../components/BackgroundImage";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ConnectModal from "../connect/ConnectModal";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yutes On The Block</title>
        <link rel="icon" href="/YOTB White ICO.ico" />
      </Helmet>
      <Header />
      <div className="centeredOnScreen w-fit ">
        <div className="relative">
          <img
            src="/background/YUTES.png"
            alt="Yutes"
            className="hidden md:flex mx-auto w-fit"
          />

          <img
            src="/background/YOTB.png"
            alt="Yutes"
            className="md:hidden mx-auto w-fit"
          />
        </div>
      </div>
      <ConnectModal />
      <BackgroundImage />
      <Footer />
    </div>
  );
}

export default Home;
