import React from "react";
import { useRecoilState } from "recoil";
import { connectModalState } from "../atoms/connectModalAtom";

function ConnectButton() {
  const [showModal, setShowModal] = useRecoilState(connectModalState);
  return (
    <button
      className="font-black text-[14px] w-56 md:mx-3 md:text-xl uppercase md:w-[260px] md:rounded-md md:border-[3px] md:border-black hover:bg-blue-500 hover:text-white p-3"
      onClick={() => setShowModal(true)}
    >
      Connect Wallet
    </button>
  );
}

export default ConnectButton;
