import React, { useState } from "react";
import { useAccount, useDisconnect } from "wagmi";

function DisconnectButton() {
  const { address } = useAccount();
  const [showDisconnect, SetShowDisconnect] = useState(false);

  const disonnectToggle = () => {
    SetShowDisconnect(!showDisconnect);
  };

  const { disconnect } = useDisconnect();
  return (
    <button
      className="font-black text-[14px] w-56 md:mx-3 md:text-xl md:w-[260px] uppercase md:rounded-md md:border-[3px] md:border-black hover:bg-blue-500 hover:text-white p-3"
      onClick={() => disconnect()}
      suppressHydrationWarning
      onMouseEnter={disonnectToggle}
      onMouseLeave={disonnectToggle}
    >
      {showDisconnect
        ? "disconnect"
        : `Wallet: ${address?.slice(0, 5)}...${address?.slice(
            address.length - 4
          )}`}
    </button>
  );
}

export default DisconnectButton;
