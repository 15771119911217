import React from "react";

function RoadmapOne() {
  return (
    <div className="mt-[60px] shadow-md bg-black/20 p-3 rounded-md">
      <h1 className="uppercase font-black text-white text-[40px] md:text-[80px]">
        Roadmap 1.0
      </h1>
      <p className="font-semibold">
        The focus of this project is to be primarily Community-driven.
      </p>

      <div className="md:flex items-center my-20">
        <img
          src="/Houses/House 1.png"
          alt="house 420"
          className="h-[120px] md:h-[200px]"
        />

        <div className="flex-1">
          <div className="flex md:justify-end items-center">
            <div className="md:w-[400px]">
              <p className="text-[23px] md:text-[32px] font-black text-white">
                Community Development
              </p>
              <div className="relative">
                <p className="absolute rotate-[-2deg] text-[11px] font-semibold uppercase pl-11 pr-11 pt-0 pb-0 bg-red-500 w-fit">
                  Classified
                </p>
                <p className="text-[14px] md:text-[18px] font-semibold">
                  Launch NFTHub
                </p>
              </div>
              <p className="text-[14px] md:text-[18px] font-semibold">
                Activate Twitter Account
              </p>
              <p className="text-[14px] md:text-[18px] font-semibold">
                Activate Discord Channel
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="md:flex items-center my-20">
        <div className="flex-1">
          <div className="flex justify-between items-center">
            <div className="md:w-[400px]">
              <p className="text-[23px] md:text-[32px] font-black text-white">
                Community Giveaways
              </p>
              <p className="text-[14px] md:text-[18px] font-semibold">
                Treasure Hunt unlocks after 30% mint, the first person to solve
                will be rewarded with 5 ETH and a Yute.
              </p>

              <p className="text-[12px] md:text-[14px] mt-5">
                More giveaways to be announced!
              </p>
            </div>
          </div>
        </div>

        <img
          src="/Houses/House 2.png"
          alt="house 421"
          className="h-[120px] md:h-[200px]"
        />
      </div>

      <div className="md:flex items-center my-20">
        <img
          src="/Bodega.png"
          alt="Bodega"
          className="h-[120px] md:h-[200px]"
        />

        <div className="flex-1">
          <div className="flex md:justify-end bg items-center">
            <div className="md:w-[400px]">
              <p className="text-[23px] md:text-[32px] font-black text-white">
                Corner Store
              </p>
              <p className="text-[14px] md:text-[18px] font-semibold">
                Members exclusive Merch.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="md:flex items-center my-20">
        <div className="flex-1">
          <div className="flex justify-between items-center">
            <div className="md:w-[400px]">
              <p className="text-[23px] md:text-[32px] font-black text-white">
                Community Wallet
              </p>
              <p className="text-[14px] md:text-[18px] font-semibold">
                Unlocks at 100% mint
              </p>
              <p className="text-[14px] md:text-[18px] font-semibold">
                $200,000
              </p>
            </div>
          </div>
        </div>

        <img src="/Bank.png" alt="Bank" className="h-[120px] md:h-[200px]" />
      </div>

      <div className="md:flex items-center my-20">
        <img
          src="/Community House.png"
          alt="Community House"
          className="h-[120px] md:h-[200px]"
        />

        <div className="flex-1">
          <div className="flex md:justify-end items-center">
            <div className="md:w-[400px]">
              <p className="text-[23px] md:text-[32px] font-black text-white">
                Community Investing
              </p>
              <p className="text-[14px] md:text-[18px] font-semibold">
                Owners of a Yute will collaborate together to decide on what the
                community invests in....... Other NFT projects, features, IRL
                businesses and more.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoadmapOne;
