import React from "react";

function RoadmapTwo() {
  return (
    <div className="mt-[60px] shadow-md bg-black/20 p-3 rounded-md">
      <h1 className="uppercase font-black text-white text-[40px] md:text-[80px]">
        Roadmap 2.0
      </h1>
      <p className="mb-20 font-bold">Q2 2023</p>
    </div>
  );
}

export default RoadmapTwo;
