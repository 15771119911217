import React from "react";

function MoveIn() {
  return (
    <div className="mt-[60px]">
      <h1 className="uppercase font-black text-white text-[40px] md:text-[80px]">
        Move In
      </h1>
      <p className="mt-[20px] text-[20px]">
        Join the block association and build with us.
      </p>
      <p className="mb-[20px] text-[20px]">
        Yutes On The Block is a community ran brand.
      </p>

      <div className="grid md:grid-cols-2 gap-4">
        <a
          href="https://opensea.io/collection/yutesontheblock"
          target="_blank"
          rel="noreferrer"
          className="rounded-md border-4 border-blue-300/20 bg-blue-300/10 shadow-md hover:scale-105 flex items-center h-20"
        >
          <img
            src="/YOTB LOGO.png"
            alt="Opensea"
            className="h-[50px] w-[50px] mx-[15px]"
          />
          <div className="text-left">
            <h1 className="font-bold">Collection</h1>
            <p className="text-sm">10,000 Yutes On The Block</p>
          </div>
        </a>

        <a
          href="https://twitter.com/YutesOnTheBlock"
          target="_blank"
          rel="noreferrer"
          className="rounded-md border-4 border-blue-300/20 bg-blue-300/10 shadow-md hover:scale-105 flex items-center h-20"
        >
          <img
            src="/Twitter logo png.png"
            alt="Twitter"
            className="h-10 w-10 mx-5"
          />
          <div className="text-left">
            <h1 className="font-bold">Twitter</h1>
            <p className="text-sm">Stay updated!</p>
          </div>
        </a>

        <a
          href="https://discord.gg/VWPQSFMyf5"
          target="_blank"
          rel="noreferrer"
          className="rounded-md border-4 border-blue-300/20 bg-blue-300/10 shadow-md hover:scale-105 flex items-center h-20"
        >
          <img
            src="/discord-black-icon-1.png"
            alt="discord"
            className="h-10 w-10 mx-5"
          />
          <div className="text-left">
            <h1 className="font-bold">Discord</h1>
            <p className="text-sm">Join The community</p>
          </div>
        </a>

        <a
          href="https://www.instagram.com/yutesontheblock/"
          target="_blank"
          rel="noreferrer"
          className="rounded-md border-4 border-blue-300/20 bg-blue-300/10 shadow-md hover:scale-105 flex items-center h-20"
        >
          <img
            src="/instagram-icon-white-on-black.png"
            alt="Instagram"
            className="h-10 mx-[14px]"
          />
          <div className="text-left">
            <h1 className="font-bold">Instagram</h1>
            <p className="text-sm">Follow us on Instagram!</p>
          </div>
        </a>
      </div>
    </div>
  );
}

export default MoveIn;
